import React, { Suspense } from "react";
import "./assets/css/style.css";
import { lazy } from "react";
const Route = lazy(() => import("./pages/route"));

function App() {
  return (
    <div className="App">
      <Route />
    </div>
  );
}

export default App;
